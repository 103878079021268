import { Injectable } from '@angular/core';
import { ToastService, ToastType } from './toast.service';
import { UserService } from './user.service';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { Feature, LoggerService } from './logger.service';
import { UserLocationState } from '../store/user-location.state';
import { SignInWithApple, SignInWithAppleResponse, SignInWithAppleOptions } from '@capacitor-community/apple-sign-in';
import { ModalController } from '@ionic/angular/standalone';
import { SocialLoginProvider } from '../providers/social-login.provider';
import { GoogleAuth, User } from '@codetrix-studio/capacitor-google-auth';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { Capacitor } from '@capacitor/core';
import { FacebookLogin, FacebookLoginResponse } from '@capacitor-community/facebook-login';

@Injectable({ providedIn: 'root' })
export class SocialLoginService {
  readonly inizialized$ = new BehaviorSubject(false);

  constructor(
    private toastService: ToastService,
    private userService: UserService,
    private loggerService: LoggerService,
    private userLocationState: UserLocationState,
    private modalController: ModalController,
    private socialLoginProvider: SocialLoginProvider,
    private translateService: TranslateService,
  ) {}

  // If you need to update this value, update the id (and the associated token) in these files:
  // ios/App/App/Info.plist
  // android/app/src/main/res/valuse/strings.xml
  private readonly FACEBOOK_CONFIG = {
    appId: '119313188407270',
  };

  private readonly IOS_CONFIG: SignInWithAppleOptions = {
    clientId: 'org.worldreader.booksmart.signin', // See https://developer.apple.com/account/resources/identifiers/serviceId
    redirectURI: environment.host,
    scopes: 'email',
    state: '12345',
  };

  private readonly GOOGLE_CONFIG = {
    webClientId: '1025042132072-v6rqp3ae1bukj9ni62u0spcuajp7q0gc.apps.googleusercontent.com',
    iOSClientId: '1025042132072-n05b10iiitln9cs3hs00tr57u469am1a.apps.googleusercontent.com',
    androidClientId: '998420314993-60g17i2cv6sasocl9blk3ki77dgdcv8m.apps.googleusercontent.com', // this is the real one
  };

  async initialize(): Promise<void> {
    await FacebookLogin.initialize({ appId: this.FACEBOOK_CONFIG.appId });

    await GoogleAuth.initialize({
      scopes: ['email', 'profile'],
      clientId: this.getGoogleClientId(),
      grantOfflineAccess: true,
    });

    this.inizialized$.next(true);
  }

  // https://github.com/capacitor-community/facebook-login/pull/150
  async facebookLogin(): Promise<boolean | void> {
    let accessToken: string | null = null;

    const result: void | FacebookLoginResponse = await FacebookLogin.login({ permissions: [] }).catch(error => {
      if (error?.accessToken?.token === null) {
        // User closed the page, do nothing
      } else {
        this.loggerService.error('Cannot login via facebook ' + error.toString(), {
          context: 'FacebookService::login() - Cannot login via facebook',
          feature: Feature.AUTHENTICATION,
        });
      }
    });

    if (result && result.accessToken?.token) {
      accessToken = result.accessToken?.token;
    }

    if (accessToken) {
      const user = await firstValueFrom(
        this.socialLoginProvider.loginWithFacebook(this.FACEBOOK_CONFIG.appId, accessToken, this.userLocationState.userLocation$.value.countryCode),
      ).catch(error => {
        void this.toastService.present({
          message: 'authentication with facebook failed. Try again later or login with an other provider',
          type: ToastType.Negative,
          displayClose: false,
        });

        this.loggerService.error(error.toString(), {
          context: 'FacebookService::login() - Cannot login via facebook - Booksmart API',
          feature: Feature.AUTHENTICATION,
        });
      });

      if (user) {
        await this.userService.login(user);
        return Promise.resolve(user.isNewAccount);
      }
    } else {
      return Promise.reject();
    }
  }

  async appleLogin(): Promise<void> {
    const result: void | SignInWithAppleResponse = await SignInWithApple.authorize(this.IOS_CONFIG).catch((error: { error: string }) => {
      console.log('----------');
      console.log(error);
      if (error?.error !== 'popup_closed_by_user') {
        this.showLoginFailedToast('apple');
      }
    });

    if (result) {
      console.log('------- result --------');
      console.log(result);
      const user = await firstValueFrom(this.socialLoginProvider.loginWithApple()).catch(() => this.showLoginFailedToast('apple'));

      if (user) {
        await this.userService.login(user);
        void this.modalController.dismiss();
      }
    }
  }

  async googleLogin(): Promise<void> {
    const result: void | User = await GoogleAuth.signIn().catch(error => {
      if (error?.error === 'popup_closed_by_user') {
        // Do nothing
      } else {
        this.loggerService.error(error.toString(), {
          context: 'SocialLoginService::googleLogin() - Cannot login via google - GoogleAuth plugin',
          feature: Feature.AUTHENTICATION,
        });
        this.showLoginFailedToast('google');
      }
    });

    console.log(result);
    if (result) {
      alert(result.authentication.accessToken);
    } else {
      alert('no result');
    }

    if (result) {
      const user = await firstValueFrom(
        this.socialLoginProvider.loginWithGoogle(
          result.authentication.idToken,
          result.authentication.accessToken,
          this.userLocationState.userLocation$.value.countryCode,
        ),
      ).catch(error => {
        this.loggerService.error(JSON.stringify(error), {
          context: 'SocialLoginService::googleLogin() - Cannot login via google - provider error',
          feature: Feature.AUTHENTICATION,
        });
        this.showLoginFailedToast('google');
      });

      if (user) {
        await this.userService.login(user);
        void this.modalController.dismiss();
      }
    }
  }

  private showLoginFailedToast(provider: 'apple' | 'facebook' | 'google'): void {
    let message;

    switch (provider) {
      case 'apple':
        message = this.translateService.instant('PWA_LoginOrCreateAccount_loginError_methodFailed_apple');
        break;
      case 'facebook':
        message = this.translateService.instant('PWA_LoginOrCreateAccount_loginError_methodFailed_facebook');
        break;
      case 'google':
        message = this.translateService.instant('PWA_LoginOrCreateAccount_loginError_methodFailed_google');
        break;
    }

    void this.toastService.present({
      message,
      type: ToastType.Negative,
      displayClose: false,
    });
  }

  private getGoogleClientId(): string {
    switch (Capacitor.getPlatform()) {
      case 'ios':
        return this.GOOGLE_CONFIG.iOSClientId;
      case 'android':
        return this.GOOGLE_CONFIG.androidClientId;
      default:
        return this.GOOGLE_CONFIG.webClientId;
    }
  }
}
